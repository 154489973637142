import React from 'react';
import { useTranslation } from 'react-i18next';

// style
import "../styles/ContactsPage.scss";

// layouts
// @ts-ignore
import Footer from "../layouts/Footer.tsx";

export default function ContactsPage() {
    const { t } = useTranslation();

    return(
        <div className="Contacts__root">
            <div className="Contacts__wrapper">
                <img src="logo.webp" className="Contacts__logo"/>

                <div className="Contacts__items-wrap">
                    <div className="Contact__container">
                        <img src="pin.png" className="Contact__icon"/>
                        <p className="Contact__content">Unit No: 908, Fortune Tower<br/>Plot No: JLT-PH1-C1A, Jumeirah Lakes Towers, Dubai, UAE<br/>P.O. Box 112467</p>
                    </div>

                    <div className="Contact__container">
                        <img src="invoice.png" className="Contact__icon"/>
                        <p className="Contact__content">{t('contacts.LICENSE')}: DMCC-832506<br/>{t('contacts.REGISTRATION')}: DMCC1921</p>
                    </div>

                    <div className="Contact__container">
                        <img src="phone-call.png" className="Contact__icon"/>
                        <p className="Contact__content">{t('contacts.PHONE')}: <a className="Contact__link" href="tel:+971045898480">+971(04)5898480</a></p>
                    </div>

                    <div className="Contact__container">
                        <img src="mail.png" className="Contact__icon"/>
                        <p className="Contact__content"><a className="Contact__link" href="mailto:info@wsyb.ae">info@wsyb.ae</a><br/><a className="Contact__link" href="mailto:fin@wsyb.ae">fin@wsyb.ae</a></p>
                    </div>
                </div>
            </div>
        <Footer/>
        </div>
    );
}