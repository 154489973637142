import React from 'react';
import { useTranslation } from 'react-i18next';

// style
import "../styles/HomePage.scss";

// layouts
// @ts-ignore
import Footer from '../layouts/Footer.tsx';

export default function HomePage() {
    const { t } = useTranslation();

    return(
        <div className="Home__root">
            <div className="slide_container1">
                <picture className='home_bg_img'>
                    <source
                        media="(max-width: 600px)"
                        srcSet="bg-new-600.webp 600w"
                        sizes="600px"
                    />
                    <img src="bg-new.webp" alt="background" style={{width:"100%",height:"70vh",objectFit:"cover"}}/>
                </picture>
                <img src="logo.webp" className="home_logo_img" alt="logo"/>
            </div>

            <p className="p_title"><span style={{color:"rgb(56, 46, 42)"}}>WSYB</span> - {t('home.TITLE')}</p>

            <div className="slide_container2">
                <div/>
            
                <div className="slide_item2">
                    <div className="_img_container">
                        <img src="home1.webp" className="slide_img" alt="slide_image"/>
                    </div>

                    <div className="_description_container">
                        <p className="a_title">{t('home.c1.TITLE')}</p>
                        <p className="a_content">{t('home.c1.DESCRIPTION')}</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="slide_container3">
                <div/>

                <div className="slide_item3">
                    <div className="_description_container">
                        <p className="a_title">{t('home.c2.TITLE')}</p>
                        <p className="a_content">{t('home.c2.DESCRIPTION').split('\n').map((text, index) => {
                            return(
                                <span>{text}<br/></span>
                            );
                        })}</p>
                    </div>
                
                    <div className="_img_container">
                        <img src="home2.webp" className="slide_img" alt="slide_image"/>
                    </div>
                </div>

                <div/>
            </div>

            <div className="slide_container2">
                <div/>
            
                <div className="slide_item2">
                    <div className="_img_container">
                        <img src="home3.webp" className="slide_img" alt="slide_image"/>
                    </div>

                    <div className="_description_container">
                        <p className="a_title">{t('home.c3.TITLE')}</p>
                        <p className="a_content">{t('home.c3.DESCRIPTION')}</p>
                    </div>
                </div>

                <div/>
            </div>

            <Footer/>
        </div>
    );
}