import React from 'react';
import { useTranslation } from 'react-i18next';

// style
import "../styles/ServicePage.scss";

// layouts
// @ts-ignore
import Footer from '../layouts/Footer.tsx';

// assets
// @ts-ignore
import prod1 from "../assets/prod1.jpg";// @ts-ignore
import prod2 from "../assets/prod2.jpg";// @ts-ignore
import prod3 from "../assets/prod3.jpg";// @ts-ignore
import prod4 from "../assets/prod4.jpg";// @ts-ignore
import prod5 from "../assets/prod5.jpg";// @ts-ignore
import prod6 from "../assets/prod6.jpg";// @ts-ignore
import prod7 from "../assets/prod7.jpg";// @ts-ignore
import prod8 from "../assets/prod8.jpg";

export default function ServicePage() {
    const { t } = useTranslation();

    return(
        <div className="Service__root">
            <p className="p_title">
                {t('service.SERVICE')}
            </p>

            <p className="p_subtitle">
                {t('service.TITLE')}
            </p>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod1} alt="product1"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT1')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod2} alt="product2"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT2')}</p>
                        <p className="a_content">&nbsp;</p>
                         {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod3} alt="product3"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT3')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod4} alt="product4"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT4')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod5} alt="product4"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT5')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod6} alt="product4"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT6')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod7} alt="product4"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT7')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <div className="list">
                <div/>
                <div className="item">
                    <div className="_service_img_c">
                        <img className="_service_img" src={prod8} alt="product4"/>
                    </div>
                    <div className="_service_desc_c">
                        <p className="a_title">{t('service.PRODUCT8')}</p>
                        <p className="a_content">&nbsp;</p>
                    </div>
                </div>

                <div/>
            </div>

            <Footer/>
        </div>
    );
}