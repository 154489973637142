import React, { useState, useEffect, useContext } from 'react';
import { useWindowDimensions } from '../windowDimensions';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import LocaleContext from '../LocaleContext';

// style
import "../styles/NavigationBar.scss";

// assets
// @ts-ignore
//import logo from "../assets/logo.png";

export default function NavigationBar() {
    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation();

    const [isPc, setIsPc] = useState<boolean>(false);
    const [location, setLocation] = useState<string>("");
    const { width } = useWindowDimensions();

    const [menuState, setMenuState] = useState<boolean>(false);

    function changeLocale (l: string) {
        if (locale !== l) {
        document.cookie  = `lang=${l}`;
          i18n.changeLanguage(l);
        }
    }

    useEffect(() => {
        const cookieLangValue = document.cookie
            .split('; ')
            .find((row) => row.startsWith('lang='))
            ?.split('=')[1];

        if(cookieLangValue == "ru" || cookieLangValue == "en") {
            changeLocale(cookieLangValue);
        }
    }, []);

    useEffect(() => {
        if(width > 800) {
            setIsPc(true);
        } else {
            setIsPc(false);
        }
    }, [width]);

    useEffect(() => {
        let _path = window.location.pathname;
        let _patharr = _path.split("/");
        let _location = _patharr[_patharr.length - 1];

        setLocation(_location);
    }, [
        window.location.pathname
    ]);

    function switchLang() {
        if(locale == "en") {
            changeLocale("ru");
        } else {
            changeLocale("en");
        }
    }

    return(
        <div className="NavBar__page">
        <div className="NavBar__root">
            {isPc &&
            <div className="_pc_menu_c">
                <div className="logo">
                    <img src="logo.webp" className="logo_img" alt="logo"/>
                </div>

                <div className="_menu_wrap">
                    <a href="/" className={`_menu_link ${location==""?" active":""}`}>{t('navigation.HOME')}</a>
                    <a href="/service" className={`_menu_link ${location=="service"?" active":""}`}>{t('navigation.SERVICE')}</a>
                    {/*<a href="/partners" className={`_menu_link ${location=="partners"?" active":""}`}>{t('navigation.PARTNERS')}</a>*/}
                    <a href="/contacts" className={`_menu_link ${location=="contacts"?" active":""}`}>{t('navigation.CONTACTS')}</a>
                    <div onClick={() => switchLang()} className="_menu_link_lang"><img className="_menu_link_lang_img" src="planet.png"/><p className="_menu_link_lang_alias">{locale.toUpperCase()}</p></div>
                </div>
            </div>
            }

            {!isPc &&
            <div className="_mob_menu_c">
                <div className="logo">
                    <img src="logo.webp" className="logo_img" alt="logo"/>
                </div>

                <div 
                    className={`_mob_menu_b${menuState?"-active":""}`}
                    onClick={() => setMenuState(!menuState)}
                >
                    <div className="p1"/>
                    <div className="p2"/>
                    <div className="p3"/>
                </div>
            </div>
            }
        </div>

        {menuState && <div className="_mob_menu_block">
            <a href="/" className={`_menu_link ${location==""?" active":""}`}>{t('navigation.HOME')}</a>
            <a href="/service" className={`_menu_link ${location=="service"?" active":""}`}>{t('navigation.SERVICE')}</a>
            {/*<a href="/partners" className={`_menu_link ${location=="partners"?" active":""}`}>{t('navigation.PARTNERS')}</a>*/}
            <a href="/contacts" className={`_menu_link ${location=="contacts"?" active":""}`}>{t('navigation.CONTACTS')}</a>
            <p className="_menu_link_lang_mob"><span onClick={() => changeLocale("ru")} style={locale=="ru"?{color:"rgb(166, 105, 49)"}:{color:"#616161"}}>RU</span> | <span onClick={() => changeLocale("en")} style={locale=="en"?{color:"rgb(166, 105, 49)"}:{color:"#616161"}}>EN</span></p>
        </div>}
        </div>
    );
}