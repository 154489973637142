import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import LocaleContext from './LocaleContext';
import i18n from './i18n';

// style
import './App.css';

// layouts
import LoadingScreen from './layouts/LoadingScreen.tsx';
import NavigationBar from './layouts/NavigationBar.tsx';

// pages
import HomePage from './pages/Home.tsx';
import ServicePage from './pages/Service.tsx';
//import PartnersPage from './pages/Partners.tsx';
import ContactsPage from './pages/Contacts.tsx';

function App() {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', (lng) => setLocale(i18n.language));

  return (
    <LocaleContext.Provider value={{locale, setLocale}}>
      <Suspense fallback={<LoadingScreen/>}>
        <Router>
          <NavigationBar/>

          <Routes>
            <Route
              exact
              path=""
              element={<HomePage/>}
            />

            <Route
              path="/service"
              element={<ServicePage/>}
            />

            {/*<Route
              path="/partners"
              element={<PartnersPage/>}
            />*/}

            <Route
              path="/contacts"
              element={<ContactsPage/>}
            />
          </Routes>
        </Router>
      </Suspense>
    </LocaleContext.Provider>
  );
}

export default App;
