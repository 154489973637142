import React, { useState, useEffect } from 'react';
import { useWindowDimensions } from '../windowDimensions';
import { useTranslation } from 'react-i18next';

// style
import "../styles/Footer.scss";

export default function Footer() {
    const { t } = useTranslation();

    const [isPc, setIsPc] = useState<boolean>(false);
    const [showImgCreds, setImgCredsState] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        if(width > 800) {
            setIsPc(true);
        } else {
            setIsPc(false);
        }
    }, [width]);

    useEffect(() => {
        if(window.location.pathname.includes('contacts')) {
            setImgCredsState(true);
        } else {
            setImgCredsState(false);
        }
    }, []);

    return(
        <div className="Footer__root">
            <div className="grid">
                {isPc &&
                <>
                    <p>WSYB © 2023</p>
                    <a className="center" href="/">{/*Terms & Conditions*/}&nbsp;</a>
                    <a className="center" href="/">{/*License Agreement*/}&nbsp;</a>
                    {showImgCreds ? <p>Icons by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a></p> : <a className="center" href="/contacts">Contact us</a>}
                    <p>{t('footer.RIGHTSRESERVED')}</p>
                    <a className="center" href="/">{/*Policy*/}&nbsp;</a>
                    <a className="center" href="/">{/*Customer support*/}&nbsp;</a>
                    <p className="end">Dev by <a href="https://t.me/feanor_gg">Ivan Galkin</a></p>
                </>
                }

                {!isPc && 
                <>
                    <p>WSYB © 2023</p>
                    <a className="center" href="/">{/*Terms & Conditions*/}&nbsp;</a>
                    <p>{t('footer.RIGHTSRESERVED')}</p>
                    {/*<a className="center" href="/">{/*Policy}&nbsp;</a>*/}
                    <div/>
                    {showImgCreds && <p>Icons by <a href="https://www.flaticon.com/authors/those-icons" title="Those Icons">Those Icons</a></p>}
                    {showImgCreds && <p>&nbsp;</p>}
                    <p className="end">Dev by <a href="https://t.me/feanor_gg">Ivan Galkin</a></p>
                    <p>&nbsp;</p>
                </>
                }
            </div>
        </div>
    );
}